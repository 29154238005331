export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive(
    'highlight',
    function (el: HTMLElement, binding: any) {
      let text = el.innerText

      if (!text) return

      const search: string = binding.value

      if (search.length > 1) {
        const regex = new RegExp(search, 'gi')

        text = text.replace(
          regex,
          (match: string) =>
            `<span class="bg-yellow-500">${match}</span>`
        )
      }

      el.innerHTML = text
    }
  )
})
